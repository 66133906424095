<template>
    <div class="d-flex pr-0 justify-content-center align-items-center flex-column">
      <div class="align-items-center d-flex flex-column">
        <div class="d-flex align-items-center">
          <ComingSoonImage />
        </div>
        <div class="text-primary">{{ $t('comingSoon.comingSoon') }}</div>
        <div class="text-secondary">{{ $t('comingSoon.featureIsNotYet') }}</div>
        <div class="card-footer border-top-0">
          <button @click="goToHome" class="btn w-100 text-button">{{ $t('comingSoon.backToHome') }}</button>
        </div>
      </div>
    </div>
</template>
<script>
import ComingSoonImage from '@/components/Icons/ComingSoon.vue'

export default {
  components: {
    ComingSoonImage,
  },
  methods: {
    goToHome() {
      this.$router.push('/dashboard')
    },
  },
}
</script>
<style>
.text-primary {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  color: #015289;
}

.text-secondary {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000 !important;
}

.text-button {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  padding: 10px 60px;
  color: #015CA1;
  border: 2.25px solid #015289;
  border-radius: 6.75px;
}
</style>
